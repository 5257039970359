@import '../_var.less';

@atom: Loader;

.@{atom} {
    color: @color-primary;

    margin: 100px auto;
    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 10px;

    & > div {
        background-color: @color-primary;
        height: 100%;
        width: 5px;
        display: inline-block;
        margin-right: 3px;

        animation: sk-stretchdelay 1.2s infinite ease-in-out;
    }

    .rect2 { animation-delay: -1.1s; }
    .rect3 { animation-delay: -1.0s; }
    .rect4 { animation-delay: -0.9s; }
    .rect5 { animation-delay: -0.8s; }
}

@keyframes sk-stretchdelay {
    0%, 40%, 100% {
        transform: scaleY(0.4);
    }
    20% {
        transform: scaleY(1.0);
    }
}